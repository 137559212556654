import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const BYUTest = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">BYU Athletics NFT - TEST</h1>
          <div className="image">
            <StaticImage src="../images/cosmo-placeholder.jpg" alt="BYU Cosmo" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>Cosmo the Cougar has been Brigham Young University’s beloved mascot for over 60 years! His talents, intelligence, charisma, honor and enthusiasm symbolize BYU standards.</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="byu-test" project-slug="main" contract-network="4" contract-address="0xb3a285ba5cF3cA0A65c0b5Afa707401dCD49b53c" infura-id="3ed982906a2d473f8918f0730b2ea194" winter-url="https://sandbox-winter-checkout.onrender.com/?projectId=3174"></hashku-mint>
    </PageLayout>
  );
};

export default BYUTest;
